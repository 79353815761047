export const selectionListMixin = {
  data() {
    return {
      shiftKeyPressed: false
    }
  },

  methods: {
    onKeyUp(event) {
      this.shiftKeyPressed = event.shiftKey
    },

    onKeyDown(event) {
      this.shiftKeyPressed = event.shiftKey
      const lastSelection = this.lastSelection
        ? this.lastSelection
        : { x: 0, y: 0 }
      const i = lastSelection.x
      const j = lastSelection.y
      let validationCell = null
      if (event.altKey && [37, 38, 39, 40].includes(event.keyCode)) {
        if (event.keyCode === 37) {
          validationCell = this.select(i, j - 1)
        } else if (event.keyCode === 38) {
          validationCell = this.select(i - 1, j)
        } else if (event.keyCode === 39) {
          validationCell = this.select(i, j + 1)
        } else if (event.keyCode === 40) {
          validationCell = this.select(i + 1, j)
        }
        this.scrollToValidationCell(validationCell)
        this.stopEvent(event)
      }
    },

    stopEvent(e) {
      if (!e) e = window.event
      e.stopPropagation()
      e.cancelBubble = true
      e.returnValue = false
    },

    setScrollPosition(scrollPosition) {
      if (this.$refs.body) {
        this.$refs.body.scrollTop = scrollPosition
      }
    },

    setScrollLeftPosition(scrollPosition) {
      if (this.$refs.body) {
        this.$refs.body.scrollLeft = scrollPosition
      }
    },

    select(i, j) {
      const ref = 'validation-' + i + '-' + j
      const validationCell = this.$refs[ref]
      if (validationCell) validationCell[0].$el.click()
      return validationCell ? validationCell[0] : 0
    },

    scrollToValidationCell(validationCell) {
      if (validationCell && this.nbSelectedTasks > 0) {
        this.$nextTick(() => {
          const margin = 20
          const headers = document.querySelectorAll(
            '.datatable-head .datatable-row-header'
          )
          let stickyHeaderWidth = 0
          headers.forEach(h => {
            stickyHeaderWidth += h.offsetWidth
          })
          const rect = validationCell.$el.getBoundingClientRect()
          const listRect = this.$refs.body.getBoundingClientRect()
          const isBelow = rect.bottom > listRect.bottom - margin
          const isAbove = rect.top < listRect.top + margin
          const isRight = rect.x + rect.width > listRect.width
          const isLeft = rect.x < stickyHeaderWidth + margin

          if (isBelow) {
            const scrollingRequired = rect.bottom - listRect.bottom + margin
            this.setScrollPosition(
              this.$refs.body.scrollTop + scrollingRequired
            )
          } else if (isAbove) {
            const scrollingRequired = listRect.top - rect.top + 2 * margin
            this.setScrollPosition(
              this.$refs.body.scrollTop - scrollingRequired
            )
          }

          if (isRight) {
            const scrollingRequired = rect.right - listRect.right + margin
            this.setScrollLeftPosition(
              this.$refs.body.scrollLeft + scrollingRequired
            )
          } else if (isLeft) {
            const scrollingRequired = stickyHeaderWidth - rect.left + 2 * margin
            this.setScrollLeftPosition(
              this.$refs.body.scrollLeft - scrollingRequired
            )
          }
        })
      }
    }
  }
}
